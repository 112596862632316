<template>
  <div>
    <base-item-group
      :item="items"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'

const query = `
  _id
  ... on StbM2021 {
    _id
    leagues {
      teams {
        team {
          _id name
          club { _id name }
        }
      }
    }
  }
`

export default {
  name: 'Menu',

  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {}
  }),

  computed: {
    ...mapGetters(['isMaster']),
    items () {
      return {
        group: `/e/${this.id}`,
        icon: 'fa-child-reaching fa-rotate-180',
        title: this.name,
        children: [
          ...this.frontend,
          ...(this.isMaster ? this.admin : []),
          ...this.teams.map(t => this.team(t))
        ]
      }
    },
    frontend () {
      return [
        {
          title: 'Übersicht',
          to: 'start',
          icon: 'mdi-view-dashboard'
        },
        {
          title: 'Tabellen',
          to: 'tables',
          icon: 'fa-table'
        }, /*
        {
          title: 'Begegnungen',
          to: 'begegnungen',
          icon: 'fa-calendar-day'
        }, */
        {
          title: 'Ranglisten',
          to: 'ranking',
          icon: 'fa-trophy-alt'
        }
      ]
    },
    admin () {
      return [
        {
          title: 'Administration',
          subtitle: this.name,
          group: 'admin',
          icon: 'fa-users-cog',
          children: [
            {
              title: 'Saison bearbeiten',
              to: 'season',
              icon: 'fa-calendar-edit'
            },
            {
              title: 'Begegnungen bearbeiten',
              to: 'events',
              icon: 'fa-calendar-alt'
            },
            {
              title: 'News bearbeiten',
              to: 'news',
              icon: 'fa-newspaper'
            },
            {
              title: 'Verantwortliche bearbeiten',
              to: 'verantwortliche',
              icon: 'fa-users'
            },
            {
              title: 'Gymnet-Meldungen',
              to: 'gymnet',
              icon: 'fa-sync'
            },
            {
              title: 'DTL-Turner',
              to: 'dtlathletes',
              icon: 'fa-person-running'
            },
            {
              title: 'Druckfunktionen',
              to: 'print',
              icon: 'fa-print'
            },
            {
              title: 'Statistiken',
              to: 'stats',
              icon: 'fa-signal-bars'
            },
            {
              title: 'Kampfrichterplanung',
              to: 'judgeplan',
              icon: 'fa-gavel'
            }
          ]
        }
      ]
    },
    teams () {
      return (this.Event?.leagues?.reduce((acc, curr) => {
        acc.push(...(curr.teams || []).map(t => ({
          _team: t.team._id,
          name: t.team.name
        })))
        return acc
      }, []) || []).filter(t => this.$store.getters.isTeam(t._team, 'm')).filter((a, i, arr) => !arr.find((b, j) => a._team === b._team && j < i)).sort((a, b) => a.name < b.name ? -1 : 1)
    }
  },

  methods: {
    team (team) {
      return {
        title: 'Mannschaft',
        subtitle: team.name,
        group: `team/${team._team}`,
        icon: 'fa-users-cog',
        children: [
          {
            title: 'Begegnungen bearbeiten',
            to: 'events',
            icon: 'far fa-calendar-edit'
          },
          {
            title: 'Turnermeldungen anzeigen',
            to: 'turner',
            icon: 'far fa-user-group'
          },
          {
            title: 'Kommunikation',
            to: 'communication',
            icon: 'far fa-envelope'
          },
          {
            title: 'Kampfrichtermeldung',
            to: 'judges',
            icon: 'far fa-person-sign'
          }
        ]
      }
    }
  },

  apollo: {
    Event: {
      query: gql`query($id: UUID!) {
        Event(id: $id) { ${query} }
      }`,
      variables () {
        return { id: this.id }
      },
      skip () {
        return !this.id
      }
    }
  }
}
</script>

<style scoped>

</style>
